<template>
  <div class="walkin">
    <div class="tabbar">
      <div class="img"></div>
      <div class="text">
        <div @click="getCheck(1)"><span :class="{'check': check === 1}">关于仁信</span><div v-show="check === 1" class="blue" /></div>
        <div @click="getCheck(2)"><span :class="{'check': check === 2}">经营理念</span><div v-show="check === 2" class="blue" /></div>
        <div @click="getCheck(3)"><span :class="{'check': check === 3}">业务方向</span><div v-show="check === 3" class="blue" /></div>
        <div @click="getCheck(4)"><span :class="{'check': check === 4}">开展情况</span><div v-show="check === 4" class="blue" /></div>
        <div @click="getCheck(5)"><span :class="{'check': check === 5}">优势积累</span><div v-show="check === 5" class="blue" /></div>
      </div>
    </div>
    <div class="about">
      <div class="title"><span>关于仁信</span></div>
      <div class="main">
        <img src="../../assets/img/img关于仁信.png" alt="">
        <span>上海仁信保险经纪有限公司是经中国保险监督管理委员会批准成立的全国性综合保险经纪公司，总部设在上海，依法向全国范围内各类商业机构、代表处、非盈利组织、政府机关等提供包括财产险、 人寿险、再保险在内的各类险种的保险中介和风险管理服务。<br>
              “诚信、专业”是仁信潜心打造的公司品牌形象，公司将通过不变的服务理念、现代的运作模式、高效的管理团队、专业的员工队伍、成熟的市场经验以及良好的伙伴关系成为客户“一生的风险顾问、贴身的保险助手” 。</span>
      </div>
    </div>
    <div class="idea">
      <div class="title"><span>经营理念</span></div>
      <span class="main">上海仁信自成立以来尊崇“诚信、专业”的企业精神，以不变的服务经营理念，在传统领域及互联网领域不断开拓、创新，以全新的服务模式、服务手段、服务品质为立足行业之本，布局经纪服务未来。</span>
    </div>
    <div class="direction">
      <div class="title"><span>业务方向</span></div>
      <div class="main">
        <span>上海仁信积极借助“互联网+”的发展机遇，充分利用电子商务平台、移动互联网等现代化工具，积极拓展业务范畴，依托国内保险公司，通过全程的产品设计、营销推广、IT支撑、运营服务、风控管理，提升行业客户的消费体验和满意度。嵌入式投标保证保险、拍卖竞拍履约保证保险成为互联网保险业务发展的一种方式。上海仁信注重数据化运营能力建设，组建独立的数据部门开展业务相关数据的收集、整理、分析与挖掘工作。基于数据能力的特长，上海仁信不但能够帮助保险公司与客户有效规避业务风险，提升经营效益，同时也获得突出的产品创新能力与持续前行的行业机遇。</span>
      </div>
    </div>
    <div class="partner">
      <div class="title"><span>开展情况</span></div>
      <span class="main">上海仁信已与十余家国内保险公司建立长期、深入的业务合作</span>
      <div class="imgs">
        <div>
          <img v-for="item in partnerList" :key="item.id" :src="partnerImg(item.picPath)" alt="">
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="title"><span>优势积累</span></div>
      <div class="main">
        <img src="../../assets/img/图层 68.png" alt="">
        <span>经过近20年艰苦耕耘，上海仁信逐步在国内保险行业树立起“（仁信e网）”等可信服务品牌，支撑各合作伙伴通过不断提升客户服务体验，在激烈的市场竞争中获得优势。同时借助自身的技术实力，建立起从保险公司到专业保险机构，从移动设备前端到后台服务管理，覆盖生产服务全业务链的服务体系。</span>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有  <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import { article } from "@/api/home";
import gsap from '../../utils/esm/all'
import { ScrollToPlugin } from '../../utils/esm/all'
gsap.registerPlugin(ScrollToPlugin)
export default {
  name: 'WalkIn',
  data () {
    return {
      check: 1,
      partnerList: [],
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    refresh() {
      article({page: 1, size: 12, sort: 'id,desc', sectionCode: '2', deleteFlag: '0'}).then(res => {
        this.partnerList = res.content
      })
    },
    partnerImg(url) {
      return `/official/api/file/preview?source=${url}`;
    },
    getCheck (type) {
      this.check = type
      switch (type) {
        case 1:
          gsap.to(window, {duration: 1, scrollTo: '.about', ease: "power1.inOut"});
          break;
        case 2:
          gsap.to(window, {duration: 1, scrollTo: '.idea', ease: "power1.inOut"});
          break;
        case 3:
          gsap.to(window, {duration: 1, scrollTo: '.direction', ease: "power1.inOut"});
          break;
        case 4:
          gsap.to(window, {duration: 1, scrollTo: '.partner', ease: "power1.inOut"});
          break;
        case 5:
          gsap.to(window, {duration: 1, scrollTo: '.advantage', ease: "power1.inOut"});
          break;
        default:
          break;
      }
      
    }
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  .img {
    height: 180px;
    background: url('../../assets/img/img走进仁信.png') no-repeat center;
  }
  .text {
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 2px rgba(172, 177, 181, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      margin: 0 50px;
      display: flex;
      flex-direction: column;
      align-items:  center;
      justify-content: center;
      height: 100%;
      position: relative;
      cursor: pointer;
      span {
        display: block;
        display: flex;
        align-items:  center;
        height: 100%;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .check {
        color: #2E4E9A;
      }
      .blue {
        width: 48px;
        height: 4px;
        background: #2E4E9A;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        margin: 0;
      }
    }
  }
}
.about {
  background-color: #F4F5F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  .title {
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 50px;
    border-bottom: solid 5px #EC6941;
    span {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      z-index: 1;
      white-space: nowrap;
    }
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    span {
      margin-left: 130px;
      width: 624px;
      height: 196px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }
  }
}
.idea {
  height: 510px;
  background: url('../../assets/img/img经营理念.png') no-repeat center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  .title {
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 50px;
    border-bottom: solid 5px #EC6941;
    span {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      z-index: 1;
      white-space: nowrap;
    }
  }
  .main {
    width: 997px;
    height: 44px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    margin-top: 200px;
  }
}
.direction {
  height: 510px;
  background: url('../../assets/img/img业务方向.png') no-repeat center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  .title {
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 50px;
    border-bottom: solid 5px #EC6941;
    span {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      z-index: 1;
      white-space: nowrap;
    }
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    margin-right: 450px;
    span {
      width: 640px;
      height: 232px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }
  }
}
.partner {
  height: 510px;
  background: url('../../assets/img/img开展情况.png') no-repeat center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  .title {
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 50px;
    border-bottom: solid 5px #EC6941;
    span {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      z-index: 1;
      white-space: nowrap;
    }
  }
  .main {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
  }
  .imgs {
    margin: 70px 0 90px;
    div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 1200px;
      margin-bottom: 40px;
      img {
        margin: 20px 10px;
        width: 180px;
        height: 60px;
      }
    }
  }
}
.advantage {
  background-color: #F4F5F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
  .title {
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 50px;
    border-bottom: solid 5px #EC6941;
    span {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      z-index: 1;
      white-space: nowrap;
    }
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    span {
      margin-left: 130px;
      width: 624px;
      height: 196px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
    }
  }
}
.foot {
  height: 64px;
  background: #393D41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B1A9A9;
}
</style>
